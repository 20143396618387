const prepareTableColumnValues = item => {
  const title = item?.keyName;
  const key = item?.keyName
    ?.toLowerCase()
    ?.trim()
    ?.split(/[ -_]/g)
    ?.map((word, index) => {
      if (index > 0) {
        if (word[0]) {
          return word.replace(word[0], word[0].toString().toUpperCase());
        }
      }
      return word;
    })
    .join('');
  const describingOrDetermining = item.describingOrDetermining;
  return { title, key, describingOrDetermining };
};

export { prepareTableColumnValues };
