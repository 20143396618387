import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Pagination, Popover, Select, Switch, Spin, Tooltip } from 'antd';
import { FilterFilled, CaretUpOutlined } from '@ant-design/icons';
import LottingTable from '../components/Table';
import ColumnsEditor from '../components/ColumnsEditor';
import * as _ from 'lodash';
import OptionalColumnsEditor from '../components/OptionalColumnsEditor';
import _digitalColumns from '../components/constant/digitalColumns';
import ExportIcon from '../components/icons/Export';
import { getSortingParamsObj } from 'managers/pages/HomeLogic';
import moment from 'moment';
import {
  selectAttributeGroupList,
  selectBusinessCenterList,
  selectItemGroupList,
  selectOsdAttributeList,
  selectRevisedOsdAttributeList,
  selectUpsertOsd,
  isLoadingAttributeGroup,
  isLoadingItemGroup
} from '../selectors/hierarchySelector';
import {
  isLoadingLotStatus,
  isLoadingUpdateLot,
  isRegenerating,
  selectItemList,
  selectLotStatus,
  selectUser,
  selectRegenerated,
  isItemsLoading
} from '../selectors/itemSelector';
import {
  loadAttributeGroup,
  loadItemGroup,
  loadItems,
  loadLotStatus,
  loadOsdAttribute,
  recalculatePaperLot,
  updateLotStatus,
  loadOsdFilters,
  loadPaperLotNumberFilter,
  loadRevisedPaperLotNumberFilter,
  loadSizeFilter,
  loadPackFilter,
  loadRevisedOsdAttribute,
  loadCatManEventFilter,
  loadCatManLotFilter,
  resetItems
} from '../actions';
import { formatSelectedKey, getUserPermission } from '../utils/Util';
import { prepareTableColumnValues } from '../managers/components/ColumnsEditorLogic';
import ReportDownloadModal from '../components/ReportDownloadModal';

export const Home = () => {
  const dispatch = useDispatch();

  // states
  const [popOverVisible, setPopOverVisible] = useState(false);
  const [showAttributes, setShowAttributes] = useState(false);
  const [showOsdAttributes, setShowOsdAttributes] = useState(false);
  const [businessCenterList, setBusinessCenterList] = useState([]);
  const [itemGroupList, setItemGroupList] = useState([]);
  const [attributeGroupList, setAttributeGroupList] = useState([]);
  const [osdAttributeList, setOsdAttributeList] = useState([]);
  const [revisedOsdAttributeList, setRevisedOsdAttributeList] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [hierarchyId, setHierarchyId] = useState(null);
  const [collapsedHeader, setcollapsedHeader] = useState(false);
  const [numFilterStringNew, setNumFilterStringNew] = useState(null);

  const [selectedBusinessCenter, setSelectedBusinessCenter] = useState(null);
  const [selectedItemGroup, setSelectedItemGroup] = useState(null);
  const [selectedAttributeGroup, setSelectedAttributeGroup] = useState(null);
  const [selectedOsdColumns, setSelectedOsdColumns] = useState([]);
  const [selectedOptionalColumns, setSelectedOptionalColumns] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedSortParams, setSelectedSortParams] = useState({});
  const [selectedFilterParams, setSelectedFilterParams] = useState({});
  const [paperLotStatus, setPaperLotStatus] = useState('START REVIEW');
  const [osdSelectedForTheFirstTime, setOsdSelectedForTheFirstTime] = useState(0);
  const [toggleClearFilter, setToggleClearFilters] = useState(false);
  const [resetAllFilters, setResetAllFilters] = useState(false);
  const [disableResetButton, setDisableResetButton] = useState(false);
  const [filters, setFilters] = useState({});
  const [sorters, setSorters] = useState({});
  const [isPartialHierarchy, setIsPartialHierarchy] = useState(false);

  // selectors
  const businessCenters = useSelector(selectBusinessCenterList);
  const itemGroups = useSelector(selectItemGroupList);
  const attributeGroups = useSelector(selectAttributeGroupList);
  const osdAttributes = useSelector(selectOsdAttributeList);
  const revisedOsdAttributes = useSelector(selectRevisedOsdAttributeList);
  const items = useSelector(selectItemList);
  const lotStatus = useSelector(selectLotStatus);
  const user = useSelector(selectUser);
  const isLotStatusLoading = useSelector(isLoadingLotStatus);
  const isUpdateLotLoading = useSelector(isLoadingUpdateLot);
  const isRegenerateProcessing = useSelector(isRegenerating);
  const regenerated = useSelector(selectRegenerated);
  const upsertOsd = useSelector(selectUpsertOsd);
  // const isDownloadReportLoading = useSelector()
  const isItemLoading = useSelector(isItemsLoading);
  const isLoadingAg = useSelector(isLoadingAttributeGroup);
  const isLoadingIg = useSelector(isLoadingItemGroup);

  useEffect(() => {
    if (businessCenters.data && businessCenters.data.length > 0) {
      setBusinessCenterList(businessCenters.data);
    }
  }, [businessCenters]);

  useEffect(() => {
    if (itemGroups.data && itemGroups.data.length > 0) {
      setItemGroupList(itemGroups.data);
    }
  }, [itemGroups]);

  useEffect(() => {
    if (attributeGroups.data && attributeGroups.data.length > 0) {
      setAttributeGroupList(attributeGroups.data);
    }
  }, [attributeGroups]);

  useEffect(() => {
    setSelectedFilterParams({});
    setSelectedSortParams({});
    clearTableFilters();
  }, [selectedBusinessCenter, selectedItemGroup, selectedAttributeGroup]);

  useEffect(() => {
    if (osdAttributes.data && osdAttributes.data.length > 0) {
      setOsdAttributeList(osdAttributes.data);
    }
  }, [osdAttributes]);

  useEffect(() => {
    if (revisedOsdAttributes.data && revisedOsdAttributes?.data?.attributeJson) {
      const attributeJsonArray = JSON.parse(revisedOsdAttributes?.data?.attributeJson);
      setRevisedOsdAttributeList(attributeJsonArray);
    } else {
      setRevisedOsdAttributeList(osdAttributes?.data);
    }
  }, [revisedOsdAttributes]);

  useEffect(() => {
    if (selectedBusinessCenter) {
      setItemGroupList([]);
      dispatch(loadItemGroup({ businessCenter: selectedBusinessCenter }));
    }
  }, [selectedBusinessCenter]);

  useEffect(() => {
    if (selectedBusinessCenter && selectedItemGroup) {
      setAttributeGroupList([]);
      dispatch(loadAttributeGroup({ businessCenter: selectedBusinessCenter, itemGroup: selectedItemGroup }));
    }
  }, [selectedItemGroup]);

  useEffect(() => {
    if (items && items.data) {
      const modifiedItems = items.data.map(item => {
        return { ...item, key: item.supc };
      });
      setItemsList(modifiedItems);
      setHierarchyId(items.hierarchyId);
      setTotalPages(items.pageCount);
      setTotalItems(items.total);
    }
    setTimeout(ResizeTable, 500);
  }, [items]);

  useEffect(() => {
    if (hierarchyId) {
      dispatch(loadOsdAttribute({ hierarchyId }));
      dispatch(loadRevisedOsdAttribute({ hierarchyId }));
      dispatch(loadOsdFilters({ hierarchyId }));
      dispatch(loadLotStatus({ hierarchyId }));
      setShowOsdAttributes(false);
      setCurrentPage(0);
      dispatch(loadPaperLotNumberFilter({ hierarchyId }));
      dispatch(loadRevisedPaperLotNumberFilter({ hierarchyId }));
      dispatch(loadPackFilter({ hierarchyId }));
      dispatch(loadSizeFilter({ hierarchyId }));
      dispatch(loadCatManEventFilter({ hierarchyId }));
      dispatch(loadCatManLotFilter({ hierarchyId }));
    }
  }, [hierarchyId]);

  useEffect(() => {
    if (lotStatus.data && lotStatus.data.status) {
      const status = lotStatus.data.status;
      if (status === 'REVIEW') {
        setPaperLotStatus('PUBLISH');
      } else if (status === 'PUBLISH') {
        setPaperLotStatus('START REVIEW');
        loadTableData(
          selectedBusinessCenter,
          selectedItemGroup,
          selectedAttributeGroup,
          currentPage,
          pageSize,
          selectedSortParams,
          selectedFilterParams
        );
        dispatch(loadPaperLotNumberFilter({ hierarchyId }));
        dispatch(loadRevisedPaperLotNumberFilter({ hierarchyId }));
      }
    } else {
      setPaperLotStatus('START REVIEW');
    }
  }, [lotStatus]);

  useEffect(() => {
    if (totalPages < currentPage) {
      setCurrentPage(0);
      loadTableData(
        selectedBusinessCenter,
        selectedItemGroup,
        selectedAttributeGroup,
        currentPage,
        pageSize,
        selectedSortParams,
        selectedFilterParams
      );
    }
  }, [totalPages]);

  useEffect(() => {
    if (regenerated && regenerated.recalculationStatus && regenerated.recalculationStatus === 'Success') {
      loadTableData(
        selectedBusinessCenter,
        selectedItemGroup,
        selectedAttributeGroup,
        currentPage,
        pageSize,
        selectedSortParams,
        selectedFilterParams
      );
      if (hierarchyId) {
        dispatch(loadPaperLotNumberFilter({ hierarchyId }));
        dispatch(loadRevisedPaperLotNumberFilter({ hierarchyId }));
      }
    }
  }, [regenerated]);

  useEffect(() => {
    if (upsertOsd && upsertOsd.data && upsertOsd.data === 'SUCCESS') {
      loadTableData(
        selectedBusinessCenter,
        selectedItemGroup,
        selectedAttributeGroup,
        currentPage,
        pageSize,
        selectedSortParams,
        selectedFilterParams
      );
    }
  }, [upsertOsd]);

  useEffect(() => {
    if (osdSelectedForTheFirstTime === 1) {
      const determiningOSDFields = osdAttributeList.filter(
        attribute => attribute.describingOrDetermining === 'Determining'
      );
      const selectedColumnKeys = determiningOSDFields.map(item => prepareTableColumnValues(item));
      handleOsdOnselectChange(selectedColumnKeys);
    }
  }, [osdSelectedForTheFirstTime]);

  useEffect(() => {
    let isAllFiltersEmpty = Object.values(filters).filter(value => value !== null)?.length === 0 || sorters?.column;
    if (resetAllFilters && isAllFiltersEmpty) {
      loadTableData(selectedBusinessCenter, selectedItemGroup, selectedAttributeGroup, currentPage, pageSize);
      setResetAllFilters(false);
    }
  }, [resetAllFilters, filters, sorters]);

  useEffect(() => {
    if ((Object.values(filters).filter(value => value !== null)?.length > 0 || sorters?.column) && hierarchyId) {
      setDisableResetButton(false);
    } else {
      setDisableResetButton(true);
    }
  }, [hierarchyId, filters, sorters]);

  useEffect(() => {
    if (filters) {
      setCurrentPage(0);
    }
  }, [filters]);

  useEffect(() => {
    // destroy method
    return () => {
      dispatch(resetItems());
    };
  }, []);

  useEffect(() => {
    const condition = !selectedAttributeGroup; // Or !hierarchyId
    setIsPartialHierarchy(condition);
  }, [selectedAttributeGroup, osdAttributeList]);

  const hide = () => {
    setPopOverVisible(false);
    setShowAttributes(false);
  };

  const handlePopOverVisibleChange = isPopOverVisible => {
    if (!isPartialHierarchy) {
      setPopOverVisible(isPopOverVisible);
    }
  };

  const handleBusinessGroupChange = businessCenter => {
    if (businessCenter) {
      setSelectedBusinessCenter(businessCenter);
    }
    setSelectedAttributeGroup(null);
    setSelectedItemGroup(null);
    setAttributeGroupList([]);
    setItemGroupList([]);
  };

  const handleItemGroupChange = itemGroup => {
    setSelectedItemGroup(itemGroup);
    setSelectedAttributeGroup(null);
    setAttributeGroupList([]);
  };
  const handleAttributeGroupChange = attributeGroup => {
    if (attributeGroup) {
      setSelectedAttributeGroup(attributeGroup);
    }
  };

  // calculate width of fixed columns

  const calcFixedColumns = () => {
    const fixed_th = document.querySelectorAll('th[class*=lotting-fixed-column]');
    const dashed_elem = document.querySelector('.additional-attributes-base');
    let width_Sum = 0;

    fixed_th.forEach((item, i) => {
      width_Sum += item.clientWidth + 16 / fixed_th.length;
    });
    dashed_elem.style.width = `${width_Sum}px`;
  };

  const handleShowAttributes = e => {
    setShowAttributes(e);
    if (selectedOptionalColumns.length == 0) {
      setSelectedOptionalColumns(_digitalColumns);
    }
    setTimeout(ResizeTable, 300);
  };
  const handleOsdShowAttributes = e => {
    setShowOsdAttributes(e);
    setOsdSelectedForTheFirstTime(osdSelectedForTheFirstTime + 1);
    setTimeout(ResizeTable, 300);
  };

  const handleOsdOnselectChange = osdColumns => {
    if (osdColumns) {
      setSelectedOsdColumns(osdColumns);
    } else {
      setSelectedOsdColumns([]);
    }
    setTimeout(ResizeTable, 300);
  };
  const handleOptionalOnselectChange = optionalColumns => {
    if (optionalColumns) {
      setSelectedOptionalColumns(optionalColumns);
    } else {
      setSelectedOptionalColumns([]);
    }
    setTimeout(ResizeTable, 300);
  };

  const loadTableData = (bc, ig, ag, currentPage, pageSize, sortParams = {}, filterParams = {}) => {
    if (bc) {
      const requestPayload = {
        bc,
        ig,
        ag,
        currentPage,
        pageSize,
        sortParams,
        filters: filterParams
      };
      dispatch(loadItems(requestPayload));
    }
  };

  const handleSearchButtonClick = () => {
    // load items for selected bc, ig, ag
    loadTableData(
      selectedBusinessCenter,
      selectedItemGroup,
      selectedAttributeGroup,
      currentPage,
      pageSize,
      selectedSortParams,
      selectedFilterParams
    );
  };

  useEffect(() => {
    loadTableData(
      selectedBusinessCenter,
      selectedItemGroup,
      selectedAttributeGroup,
      currentPage,
      pageSize,
      selectedSortParams,
      selectedFilterParams
    );
  }, [currentPage, pageSize]);

  const tableChangeHandler = (pagination, filters, sorter, extra) => {
    let sortParams = null;
    let filterParams = {};
    let osdColumnFilters = {};
    const numericFilters = {
      greaterThan: {},
      lessThan: {},
      equal: {}
    };
    const osdColumnList = osdAttributeList.map(item => prepareTableColumnValues(item));
    setFilters(filters);
    setSorters(sorter);

    if (filters) {
      Object.keys(filters).forEach(key => {
        if (filters[key] && filters[key].length > 0) {
          const _filters = filters[key]
            ? filters[key].map(item => (typeof item === 'string' ? item?.trim() : item))
            : [];
          const osdColumn = osdColumnList?.find(column => column.key === key);
          if (osdColumn) {
            osdColumnFilters[osdColumn.title] = _filters.length > 0 ? _filters : null;
          } else if (['kviItemFlag', 'kviLotFlag'].includes(key)) {
            if (_filters.length === 1) {
              filterParams[key] = _filters[0];
            }
          } else if (key === 'revisedPgmLot') {
            filterParams['revisedPaperLotNumber'] = _filters.length > 0 ? _filters : null;
          } else if (['kviItem', 'preferredTotalCaseCount', 'preferredPack'].includes(key)) {
            if (numFilterStringNew && numFilterStringNew[key]) {
              numericFilters[numFilterStringNew[key]] = {
                ...numericFilters[numFilterStringNew[key]],
                [key]: formatSelectedKey(_filters)
              };
            }

            filterParams.numericFilters = {
              ...numericFilters
            };
          } else {
            filterParams[key] = _filters.length > 0 ? _filters : null;
          }
        }
      });
      filterParams['osdFilters'] = osdColumnFilters;
    }

    if (sorter && sorter.columnKey && sorter.order) {
      const osdColumn = osdColumnList?.find(column => column.key === sorter.columnKey);
      if (osdColumn) {
        const osdSortParams = getSortingParamsObj(osdColumn.title, sorter.order, lotStatus?.data?.status === 'REVIEW');
        sortParams = { osdAsc: osdSortParams.asc, osdDesc: osdSortParams.desc };
      } else {
        sortParams = getSortingParamsObj(sorter.columnKey, sorter.order, lotStatus?.data?.status === 'REVIEW');
      }
    }
    setSelectedSortParams(sortParams);
    setSelectedFilterParams(filterParams);
    if (Object.values(filters).length > 0) {
      loadTableData(
        selectedBusinessCenter,
        selectedItemGroup,
        selectedAttributeGroup,
        currentPage,
        pageSize,
        sortParams,
        filterParams
      );
    }
    setTimeout(ResizeTable, 500);
  };

  const reFetchData = () => {
    loadTableData(
      selectedBusinessCenter,
      selectedItemGroup,
      selectedAttributeGroup,
      currentPage,
      pageSize,
      selectedSortParams,
      selectedFilterParams
    );
    setTimeout(ResizeTable, 500);
  };

  const handleReview = () => {
    if (paperLotStatus === 'START REVIEW') {
      const updateStatusDto = { initiatedBy: user.email, status: 'REVIEW' };
      dispatch(updateLotStatus({ hierarchyId, updateStatusDto }));
    } else if (paperLotStatus === 'PUBLISH') {
      const updateStatusDto = { initiatedBy: user.email, status: 'PUBLISH' };
      dispatch(updateLotStatus({ hierarchyId, updateStatusDto }));
    }
  };

  const handleRecalculate = () => {
    dispatch(recalculatePaperLot({ hierarchyId }));
  };
  const getHierarchyStatusDetails = () => {
    if (lotStatus && lotStatus.data) {
      if (lotStatus.data.status === 'REVIEW') {
        return (
          <>
            <div className="by">
              Reviewing by: <strong>{lotStatus.data.createdBy}</strong>
            </div>
            <div className="date">
              Start date:{' '}
              <strong>
                {moment
                  .utc(lotStatus.data.createdTimestamp)
                  .local()
                  .format('D MMM YYYY h:mma')}
              </strong>
            </div>
          </>
        );
      } else if (lotStatus.data.status === 'PUBLISH') {
        return (
          <>
            <div className="by">
              Published by: <strong>{lotStatus.data.createdBy}</strong>
            </div>
            <div className="date">
              Published date:{' '}
              <strong>
                {moment
                  .utc(lotStatus.data.createdTimestamp)
                  .local()
                  .format('D MMM YYYY h:mma')}
              </strong>
            </div>
          </>
        );
      }
    }
  };

  const clearTableFilters = () => {
    const tempToggle = !toggleClearFilter;
    setToggleClearFilters(tempToggle);
  };

  const getNumFilterType = numFilterValuesMap => {
    setNumFilterStringNew(numFilterValuesMap);
  };

  const ResizeTable = () => {
    const elem = document.querySelectorAll('.list-table .ant-table-body')[0];
    const emptyElem = document.querySelectorAll('.ant-table-empty .ant-table-placeholder td')[0];
    console.log(emptyElem);
    const modifier = 18;
    let heightChange;

    if (elem) {
      heightChange =
        document.getElementById('app-bar').offsetHeight +
        document.getElementById('grid-filter-panel').offsetHeight +
        document.getElementById('filter-wrapper').offsetHeight +
        document.querySelectorAll('.ant-table-header')[0].offsetHeight +
        document.querySelectorAll('.ant-table-footer')[0].offsetHeight +
        modifier;

      elem.style.maxHeight = 'calc(100vh - ' + heightChange + 'px)';
      elem.style.minHeight = 'calc(100vh - ' + heightChange + 'px)';
    }
    if (emptyElem) {
      heightChange =
        document.getElementById('app-bar').offsetHeight +
        document.getElementById('grid-filter-panel').offsetHeight +
        document.getElementById('filter-wrapper').offsetHeight +
        document.querySelectorAll('.ant-table-thead')[0].offsetHeight +
        document.querySelectorAll('.ant-table-footer')[0].offsetHeight +
        modifier;

      emptyElem.style.height = 'calc(98vh - ' + heightChange + 'px)';
    }
  };

  const toggleCollapsed = () => {
    setcollapsedHeader(!collapsedHeader);
    setTimeout(ResizeTable, 300);
  };

  return (
    <>
      {isRegenerateProcessing || isLotStatusLoading || isUpdateLotLoading ? (
        <div className="overlay">
          <Spin spinning={true} />
        </div>
      ) : (
        ''
      )}

      <div className={`grid-filter-panel ${collapsedHeader && 'collapsed'}`} id="grid-filter-panel">
        <div
          className="back-to-dashboard"
          onClick={() => {
            window.location.href = '/suite/dashboard';
          }}
        />
        <div className="bread-crum-seperator"></div>
        <div className="title">
          Lotting
          <div className="subtitle">
            Welcome Back , to begin select a Business center, item group, and attribute group.
          </div>
        </div>
      </div>

      <div className={`filter-wrapper ${collapsedHeader && 'collapsed'}`} id="filter-wrapper">
        {/* DEV NOTE: Change 'Collaps' to 'Show' */}
        <Tooltip title="Collapse Header">
          {/* DEV NOTE: Add collapsed class when clicked */}
          <div className={`collapser ${collapsedHeader && 'collapsed'}`} onClick={toggleCollapsed}>
            <CaretUpOutlined className="icon" />
          </div>
        </Tooltip>

        <div className="filterbar">
          <div className="filtergroup market">
            <div className="filtergroup-title">
              <div className="icon"></div>
              BUSINESS CENTER
            </div>
            <div className="filtergroup-row">
              <div className="filteritem">
                <Select
                  showArrow
                  showSearch
                  className="filter-select"
                  placeholder="Business center"
                  onChange={handleBusinessGroupChange}
                  disabled={businessCenterList.length <= 0}
                  value={selectedBusinessCenter}
                >
                  {businessCenterList.length > 0 &&
                    businessCenterList
                      .sort((a, b) => a.bcName.localeCompare(b.bcName))
                      .map(obj => {
                        return (
                          <Select.Option key={obj.bcName} value={obj.bcName}>
                            {obj.bcName}
                          </Select.Option>
                        );
                      })}
                </Select>
              </div>
            </div>
          </div>
          <div className="filtergroup item-grp">
            <div className="filtergroup-title">
              <div className="icon"></div>
              ITEM GROUP
            </div>
            <div className="filtergroup-row">
              <div className="filteritem">
                <Select
                  showArrow
                  showSearch
                  className="filter-select"
                  placeholder="Item Group"
                  onChange={handleItemGroupChange}
                  disabled={itemGroupList.length <= 0}
                  value={selectedItemGroup}
                  loading={isLoadingIg}
                >
                  {itemGroupList.length > 0 &&
                    itemGroupList
                      .sort((a, b) => a.igName.localeCompare(b.igName))
                      .map(obj => {
                        return (
                          <Select.Option key={obj.igName} value={obj.igName}>
                            {obj.igName}
                          </Select.Option>
                        );
                      })}
                </Select>
              </div>
              <div className="filteritem"></div>
            </div>
          </div>

          <div className="filtergroup attribute-grp">
            <div className="filtergroup-title">
              <div className="icon"></div>
              ATTRIBUTE GROUP
            </div>
            <div className="filtergroup-row">
              <div className="filteritem">
                <Select
                  showArrow
                  showSearch
                  className="filter-select"
                  placeholder="Attribute Group"
                  onChange={handleAttributeGroupChange}
                  disabled={attributeGroupList.length <= 0}
                  value={selectedAttributeGroup}
                  loading={isLoadingAg}
                >
                  {attributeGroupList.length > 0 &&
                    attributeGroupList
                      .sort((a, b) => a.agName.localeCompare(b.agName))
                      .map(obj => {
                        return (
                          <Select.Option key={obj.agName} value={obj.agName}>
                            {obj.agName}
                          </Select.Option>
                        );
                      })}
                </Select>
              </div>
            </div>
          </div>

          <div className="filtergroup">
            <div className="filtergroup-title filter-title-search"></div>
            <div className="filtergroup-row">
              <div className="filteritem">
                <Button
                  type="primary"
                  className="btn searchbtn"
                  onClick={handleSearchButtonClick}
                  disabled={!selectedBusinessCenter}
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="additional-elements-wrapper">
          <div className="additional-attributes-wrapper">
            <div className="additional-attributes-switch">
              {isPartialHierarchy ? 'Show OSD Attributes String' : 'Show OSD Attributes'}
              <Switch
                checked={showOsdAttributes}
                disabled={isPartialHierarchy ? !selectedBusinessCenter : osdAttributeList.length <= 0}
                onChange={e => handleOsdShowAttributes(e)}
              />
            </div>
            <div className={showOsdAttributes ? 'osd-attributes-base attributes-visible' : 'osd-attributes-base'}>
              <Popover
                content={
                  <ColumnsEditor
                    osdAttributesList={osdAttributeList}
                    isDefaultAllSelected={false}
                    handleOnselectChange={handleOsdOnselectChange}
                    hierarchyId={hierarchyId}
                  />
                }
                trigger="click"
                placement="left"
                getPopupContainer={trigger => trigger.parentElement}
                visible={!isPartialHierarchy && popOverVisible}
                onVisibleChange={handlePopOverVisibleChange}
              >
                {showOsdAttributes && (
                  <Button size="default" className="btn edit-col-btn" disabled={isPartialHierarchy}>
                    EDIT COLUMNS
                  </Button>
                )}
              </Popover>
            </div>
          </div>
          <div className="elements-separator">
            <svg width="1px" height="2rem">
              <line x1="0.5" y1="0.5" x2="0.5" y2="206.5" stroke="#979797"></line>
            </svg>
          </div>
          <div className="optional-attributes-wrapper">
            <div className="optional-attributes-switch">
              Show Other Columns
              <Switch onChange={e => handleShowAttributes(e)} />
            </div>
            <div
              className={showAttributes ? 'optional-attributes-base attributes-visible' : 'optional-attributes-base'}
            >
              <Popover
                content={
                  <OptionalColumnsEditor
                    otherAttributeList={selectedOptionalColumns}
                    handleOnselectChange={handleOptionalOnselectChange}
                    hierarchyId={hierarchyId}
                  />
                }
                trigger="click"
                placement="left"
                getPopupContainer={trigger => trigger.parentElement}
              >
                {showAttributes && (
                  <Button size="default" className="btn edit-col-btn">
                    EDIT COLUMNS
                  </Button>
                )}
              </Popover>
            </div>
          </div>
          <div className="elements-separator">
            <svg width="1px" height="2rem">
              <line x1="0.5" y1="0.5" x2="0.5" y2="206.5" stroke="#979797"></line>
            </svg>
          </div>
          <Button
            type="primary"
            className="clear-col-filters-btn"
            ghost
            disabled={disableResetButton || isItemLoading}
            onClick={() => {
              clearTableFilters();
              setResetAllFilters(true);
            }}
          >
            <FilterFilled />
            Clear All Column Filters
          </Button>
        </div>
      </div>
      {itemsList && (
        <div
          className={`list-table ${collapsedHeader && 'expanded'}  ${(showOsdAttributes || showAttributes) &&
            'has-osd-optional-columns'}`}
        >
          <LottingTable
            showAttributes={showAttributes}
            showOsdAttributes={showOsdAttributes}
            osdAttributes={osdAttributeList}
            revisedOsdAttributes={revisedOsdAttributeList}
            selectedOsdColumns={selectedOsdColumns}
            selectedOptionalColumns={selectedOptionalColumns}
            tableData={itemsList}
            tableChangeHandler={tableChangeHandler}
            lotStatus={lotStatus}
            toggleClearFilters={toggleClearFilter}
            getNumFilterType={getNumFilterType}
            reFetchData={reFetchData}
            isPartialHierarchy={isPartialHierarchy}
          >
            <div className="published-status">{getHierarchyStatusDetails()}</div>
            <div className="footer-base">
              <div className="footer-left">
                <div className="footer-left-buttons">
                  <Button
                    type="primary"
                    onClick={handleRecalculate}
                    loading={isRegenerateProcessing}
                    disabled={!hierarchyId || getUserPermission(user) === 'VIEW' || isPartialHierarchy}
                  >
                    RECALCULATE
                  </Button>
                  <Button
                    type="primary"
                    className="footer-review-button"
                    onClick={handleReview}
                    loading={isLotStatusLoading || isUpdateLotLoading}
                    disabled={!hierarchyId || getUserPermission(user) === 'VIEW'}
                  >
                    {paperLotStatus}
                  </Button>
                </div>
                {paperLotStatus === 'START REVIEW' ? (
                  <div className="footer-left-hint">Click Start Review to make any changes</div>
                ) : (
                  <div className="footer-left-hint">Click Publish to confirm the applied changes</div>
                )}
              </div>
              <div className="footer-right">
                {/*<div className="footer-right-share">
                  <Button
                    type="text"
                    shape="round"
                    size="middle"
                    icon={<ExportIcon width="20px" height="20px" />}
                    disabled={!hierarchyId}
                  >
                    EXPORT
                  </Button>
                </div>*/}

                <Pagination
                  total={totalItems}
                  current={currentPage + 1}
                  showSizeChanger={true}
                  showTotal={total => `Total ${total} items`}
                  defaultPageSize={20}
                  onChange={(current, size) => {
                    setCurrentPage(current - 1);
                    setPageSize(size);
                  }}
                />
                <ReportDownloadModal bc={_.get(items, 'data.[0].osdBusinessCenter', null)} hierarchyId={hierarchyId} />
              </div>
            </div>
          </LottingTable>
        </div>
      )}
    </>
  );
};

export default Home;
