import _ from 'lodash';
import { Modal, Button } from 'antd';
import { bindActionCreators } from 'redux';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as actionCreators from '../actions';
import { dateFormatter } from './../utils/Util';
import {
  isLoadingBcReport,
  bcReportData,
  bcReportErrorSelector,
  isLoadingAgReport,
  agReportData
} from '../selectors/reportDownloadSelector';

export default function ReportDownloadModal({ bc, hierarchyId }) {
  const dispatch = useDispatch();
  const { getBcLevelReportData, downloadAgLevelReport } = bindActionCreators(actionCreators, dispatch);

  // Global State
  const loadingBCReport = useSelector(isLoadingBcReport);
  const bcReport = useSelector(bcReportData);
  const bcReportError = useSelector(bcReportErrorSelector);
  const loadingAgReport = useSelector(isLoadingAgReport);
  const agReport = useSelector(agReportData);

  // Local State
  const [isVisible, SetIsVisible] = useState(false);

  useEffect(() => {
    if (agReport?.url) {
      const link = document.createElement('a');
      link.href = agReport.url;
      link.setAttribute('download', agReport.fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }, [agReport]);

  const toggleModal = () => {
    if (!isVisible) {
      getBcLevelReportData(bc);
    }
    SetIsVisible(!isVisible);
  };

  const handleBCDownload = () => {
    const url = bcReport.url;
    window.open(url, '_blank');
  };

  const handleAGDownload = () => {
    downloadAgLevelReport({ hierarchyId });
  };

  const textLabel = bcReportError
    ? bcReportError
    : `Created at: ${dateFormatter(bcReport.lastModifiedDate, 'MM/DD/YYYY hh:mm:ss A')}`;

  return (
    <React.Fragment>
      <Button type="primary" disabled={!bc} onClick={toggleModal}>
        Download Report
      </Button>
      <Modal className="reports-modal" title="Lotting Reports" visible={isVisible} onCancel={toggleModal} footer={null}>
        <div className="content-container">
          <div className="button-group">
            <div className="button-wrapper">
              <Button
                className="modal-footer-button"
                type="primary"
                title="Downloads the daily pre-generated Business Center level report for the selected Business center"
                onClick={handleBCDownload}
                style={{ marginTop: '10px' }}
                disabled={loadingBCReport || _.isEmpty(bcReport)}
              >
                Download BC level Report
              </Button>
              <div className="text-label">{textLabel}</div>
            </div>
            <div className="button-wrapper">
              <Button
                className="modal-footer-button"
                type="primary"
                title="Downloads the latest Attribute level report for the selected Hierarchy"
                onClick={handleAGDownload}
                style={{ marginTop: '10px' }}
                disabled={!hierarchyId || loadingAgReport}
                loading={loadingAgReport}
              >
                Download AG level Report
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}
