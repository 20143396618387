import { put } from 'redux-saga/effects';
import { getRequest, putRequest } from '_http';
import {
  BUSINESS_CENTER,
  ITEM_GROUP,
  ATTRIBUTE_GROUP,
  OSD_ATTRIBUTE,
  REVISED_OSD_ATTRIBUTE,
  UPSERT_REVISED_OSD,
  OSD_FILTERS,
  PAPER_LOT_NUMBER_FILTER,
  REVISED_PAPER_LOT_NUMBER_FILTER,
  PACK_FILTER,
  SIZE_FILTER,
  CAT_MAN_EVENT_FILTER,
  CAT_MAN_LOT_FILTER
} from 'actions/actionTypes';

import { action } from 'reduxHelpers';

function* loadBusinessCentersAsync() {
  try {
    const response = yield getRequest(`/bc`);
    yield put({ type: BUSINESS_CENTER.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: BUSINESS_CENTER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'BUSINESS CENTER LOADING ERROR'
      })
    );
  }
}

function* loadItemGroupsAsync(payload) {
  try {
    const { businessCenter } = payload;
    if (businessCenter) {
      const response = yield getRequest(`/ig?bc=${encodeURIComponent(businessCenter)}`);
      yield put({ type: ITEM_GROUP.SUCCESS, payload: response.data });
    }
  } catch (error) {
    yield put({ type: ITEM_GROUP.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'ITEM GROUP LOADING ERROR'
      })
    );
  }
}
function* loadAttributeGroupAsync(payload) {
  try {
    const { businessCenter, itemGroup } = payload;
    const response = yield getRequest(`/ag?bc=${encodeURIComponent(businessCenter)}&ig=${itemGroup}`);
    yield put({ type: ATTRIBUTE_GROUP.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ATTRIBUTE_GROUP.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'ATTRIBUTE GROUP LOADING ERROR'
      })
    );
  }
}
function* loadOSDAttributesAsync(payload) {
  try {
    const { hierarchyId } = payload;
    const osdResponse = yield getRequest(`/osd-attributes?hierarchy-id=${hierarchyId}`);
    yield put({ type: OSD_ATTRIBUTE.SUCCESS, payload: osdResponse.data });
  } catch (error) {
    yield put({ type: OSD_ATTRIBUTE.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'OSD ATTRIBUTE LOADING ERROR'
      })
    );
  }
}

function* loadRevisedOsdAttributesAsync(payload) {
  try {
    const { hierarchyId } = payload;
    const revisedResponse = yield getRequest(`/osd-attributes/revised?hierarchy-id=${hierarchyId}`);
    yield put({ type: REVISED_OSD_ATTRIBUTE.SUCCESS, payload: revisedResponse.data });
  } catch (error) {
    yield put({ type: REVISED_OSD_ATTRIBUTE.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'REVISED OSD ATTRIBUTE LOADING ERROR'
      })
    );
  }
}

function* createOrUpdateRevisedOsdAsync(payload) {
  try {
    const { hierarchyId, revisedOsd } = payload;
    const url = `/osd-attributes/revised?hierarchy-id=${hierarchyId}`;
    const response = yield putRequest(url, revisedOsd);
    if (response) {
      yield put({ type: UPSERT_REVISED_OSD.SUCCESS, payload: { data: 'SUCCESS' } });
      yield put({ type: REVISED_OSD_ATTRIBUTE.REQUEST, hierarchyId });
      yield put(
        action('SHOW_NOTIFICATION', {
          className: 'success',
          message: 'Updating Revised OSD Success!'
        })
      );
    }
  } catch (error) {
    yield put({ type: UPSERT_REVISED_OSD.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'UPSERT REVISED OSD LOADING ERROR'
      })
    );
  }
}

function* loadOsdFiltersAsync(payload) {
  try {
    const { hierarchyId } = payload;
    if (hierarchyId) {
      const response = yield getRequest(`/items/osd-attributes/filters?hierarchy-id=${hierarchyId}`);
      yield put({ type: OSD_FILTERS.SUCCESS, payload: response.data });
    }
  } catch (error) {
    yield put({ type: OSD_FILTERS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'OSD FILTERS LOADING ERROR'
      })
    );
  }
}
function* loadPaperLotNumberFiltersAsync(payload) {
  try {
    const { hierarchyId } = payload;
    if (hierarchyId) {
      const response = yield getRequest(`/items/paper-lot-numbers?hierarchy-id=${hierarchyId}`);
      yield put({ type: PAPER_LOT_NUMBER_FILTER.SUCCESS, payload: response.data });
    }
  } catch (error) {
    yield put({ type: PAPER_LOT_NUMBER_FILTER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'PAPER LOT NUMBER FILTERS LOADING ERROR'
      })
    );
  }
}

function* loadRevisedPaperLotNumberFiltersAsync(payload) {
  try {
    const { hierarchyId } = payload;
    if (hierarchyId) {
      const response = yield getRequest(`/items/revised-paper-lot-numbers?hierarchy-id=${hierarchyId}`);
      yield put({ type: REVISED_PAPER_LOT_NUMBER_FILTER.SUCCESS, payload: response.data });
    }
  } catch (error) {
    yield put({ type: REVISED_PAPER_LOT_NUMBER_FILTER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'REVISED PAPER_LOT NUMBER FILTER LOADING ERROR'
      })
    );
  }
}

function* loadPackFiltersAsync(payload) {
  try {
    const { hierarchyId } = payload;
    if (hierarchyId) {
      const response = yield getRequest(`/items/packs?hierarchy-id=${hierarchyId}`);
      yield put({ type: PACK_FILTER.SUCCESS, payload: response.data });
    }
  } catch (error) {
    yield put({ type: PACK_FILTER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'PACK FILTER LOADING ERROR'
      })
    );
  }
}

function* loadSizeFiltersAsync(payload) {
  try {
    const { hierarchyId } = payload;
    if (hierarchyId) {
      const response = yield getRequest(`/items/sizes?hierarchy-id=${hierarchyId}`);
      yield put({ type: SIZE_FILTER.SUCCESS, payload: response.data });
    }
  } catch (error) {
    yield put({ type: SIZE_FILTER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'SIZE FILTER LOADING ERROR'
      })
    );
  }
}

function* loadCatManEventFiltersAsync(payload) {
  try {
    const { hierarchyId } = payload;
    if (hierarchyId) {
      const response = yield getRequest(`/items/catman_events?hierarchy-id=${hierarchyId}`);
      yield put({ type: CAT_MAN_EVENT_FILTER.SUCCESS, payload: response.data });
    }
  } catch (error) {
    yield put({ type: CAT_MAN_EVENT_FILTER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'CATMAN EVENT FILTER LOADING ERROR'
      })
    );
  }
}

function* loadCatManLotFiltersAsync(payload) {
  try {
    const { hierarchyId } = payload;
    if (hierarchyId) {
      const response = yield getRequest(`/items/catman_lots?hierarchy-id=${hierarchyId}`);
      yield put({ type: CAT_MAN_LOT_FILTER.SUCCESS, payload: response.data });
    }
  } catch (error) {
    yield put({ type: CAT_MAN_LOT_FILTER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'CATMAN LOTS FILTER LOADING ERROR'
      })
    );
  }
}

export {
  loadBusinessCentersAsync,
  loadItemGroupsAsync,
  loadAttributeGroupAsync,
  loadOSDAttributesAsync,
  loadRevisedOsdAttributesAsync,
  createOrUpdateRevisedOsdAsync,
  loadOsdFiltersAsync,
  loadPaperLotNumberFiltersAsync,
  loadRevisedPaperLotNumberFiltersAsync,
  loadPackFiltersAsync,
  loadSizeFiltersAsync,
  loadCatManEventFiltersAsync,
  loadCatManLotFiltersAsync
};
