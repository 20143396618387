const item = {
  fetching: false,
  data: [],
  hierarchyId: null,
  pageCount: null,
  pageSize: null,
  error: null
};

export default item;
