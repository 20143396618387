import initialState from 'store/initialState';
const item = (state = initialState, action) => {
  switch (action.type) {
    case 'PAPERLOT_ITEM_REQUEST':
      return { ...state, fetching: true, error: null };
    case 'PAPERLOT_ITEM_SUCCESS':
      return { ...state, fetching: false, data: action.payload };
    case 'PAPERLOT_ITEM_FAILURE':
      return { ...state, fetching: false, data: [], error: action.error };
    case 'PAPERLOT_ITEM_RESET':
      return initialState;
    default:
      return state;
  }
};

export default item;
