import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import AppLoader from 'components/AppLoader';
import * as userActions from 'actions';
import Home from './pages/Home';

// TODO: Add the correct app logo
import 'antd/dist/antd.css';
import AppLogo from './styles/images/app-logos/contacts-logo.svg';
import styles from './styles/main.scss';

const setAppLogo = () => {
  if (document.getElementById('app-logo')) {
    document.getElementById('app-logo').src = AppLogo;
  }
};

const setAppHeaderToAppMode = () => {
  if (document.getElementById('app-bar')) {
    document.getElementById('app-bar').className = 'app-bar app-mode';
  }
};

function MainApp({ suvc, userId, userPermissions, actions, userFetching }) {
  setAppLogo();
  setAppHeaderToAppMode();

  useEffect(() => {
    styles.use();
    return () => {
      styles.unuse();
    };
  }, []);

  useEffect(() => {
    actions.loadUserDetails(userId);
    actions.loadBusinessCenters();
  }, [actions, userId]);
  if (!userFetching) {
    return (
      <React.Fragment>
        <BrowserRouter>
          <Switch>
            <Route exact path="/suite/paperlot" component={Home} />
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
  }
  return <AppLoader show />;
}

function mapStateToProps(state) {
  return {
    user: state.user.data,
    userPermissions: state.user.data.permissions,
    userFetching: state.user.fetching
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(userActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainApp);
