import _ from 'lodash';

const selectItemList = state => _.get(state.item, 'data', []);
const isItemsLoading = state => _.get(state.item, 'fetching', false);
const userInput = state => _.get(state.userInput, 'data', {});
const selectUser = state => _.get(state.user, 'data', {});
const selectLotStatus = state => _.get(state.getLotStatus, 'data', null);
const isLoadingLotStatus = state => _.get(state.getLotStatus, 'fetching', false);
const isLoadingUpdateLot = state => _.get(state.updateLotStatus, 'fetching', false);
const isRegenerating = state => _.get(state.recalculate, 'fetching', false);
const selectRegenerated = state => _.get(state.recalculate, 'data', null);
const userInputLoading = state => _.get(state.userInput, 'fetching', false);
const userInputError = state => _.get(state.userInput, 'error', null);

export {
  selectItemList,
  isItemsLoading,
  selectUser,
  selectLotStatus,
  isLoadingLotStatus,
  isLoadingUpdateLot,
  userInput,
  isRegenerating,
  selectRegenerated,
  userInputLoading,
  userInputError
};
