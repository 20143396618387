const getSortingParamsObj = (field, order, isReview) => {
  const userInputs = [
    'contractEndSale',
    'contractEndDate',
    'underContract',
    'doNotSource',
    'verified',
    'verifiedBy',
    'validatedUpdatedTime',
    'validated',
    'validatedBy',
    'verifiedUpdatedTime',
    'status',
    'updatedBy',
    'updatedTime',
    'brandDominatorFlag',
    'packageSizeFlag',
    'notesToSourcing',
    'preferredSize',
    'preferredPack',
    'preferredTotalCaseCount',
    'brandNameDesired',
    'pgmRevisedLotNameDescription',
    'futureBrandStrategy',
    'revisedPgmLot',
    'pqaSpecItem'
  ];

  if (userInputs.includes(field)) {
    if (isReview) {
      field = 'userInput.review' + field.replace(field[0], field[0].toString().toUpperCase());
    } else {
      field = 'userInput.' + field;
    }
  }
  switch (order) {
    case 'ascend':
      return { asc: [field], desc: [] };
    case 'descend':
      return { asc: [], desc: [field] };
    default:
      return { asc: [], desc: [] };
  }
};

export { getSortingParamsObj };
