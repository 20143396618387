import { put } from 'redux-saga/effects';
import { putRequest } from '_http';
import { USER_INPUTS } from 'actions/actionTypes';
import { action } from 'reduxHelpers';

function* saveUserInputsAsync(payload) {
  try {
    const { data } = payload;
    const url = `/items/save-paper-lot`;
    const response = yield putRequest(url, data);
    if (response) {
      yield put({ type: USER_INPUTS.SUCCESS, payload: { data: 'success' } });
      yield put(
        action('SHOW_NOTIFICATION', {
          description: '',
          className: 'success',
          message: 'USER INPUTS UPDATED SUCCESSFULLY'
        })
      );
    }
  } catch (error) {
    yield put({ type: USER_INPUTS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'USER INPUT ERROR'
      })
    );
  }
}

export { saveUserInputsAsync };
