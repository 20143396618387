const numberWithCommas = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const getSearchWords = searchText => {
  if (searchText) {
    return [searchText.trim()];
  }
  return [];
};
export { numberWithCommas, getSearchWords };
