import initialState from 'store/initialState';

import { REVISED_PAPER_LOT_NUMBER_FILTER } from 'actions/actionTypes';

import { createReducer } from 'reduxHelpers';

const revisedPaperLotNumberFilter = createReducer(
  REVISED_PAPER_LOT_NUMBER_FILTER,
  initialState.revisedPaperLotNumberFilter
);

export default revisedPaperLotNumberFilter;
