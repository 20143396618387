import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { action } from 'reduxHelpers';
import { AG_LEVEL_REPORT, BC_LEVEL_REPORT } from 'actions/actionTypes';

function* downloadAgReportAsync(payload) {
  try {
    const { hierarchyId } = payload;

    const url = `/paper-lot-report?hierarchy-id=${encodeURIComponent(hierarchyId)}`;
    const response = yield getRequest(url);
    yield put({ type: AG_LEVEL_REPORT.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: AG_LEVEL_REPORT.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'PAPER LOT REPORT DOWNLOADING ERROR'
      })
    );
  }
}

function* bcLevelReportDataAsync({ bc }) {
  try {
    const response = yield getRequest(`/latest-bc-report?business-center=${encodeURIComponent(bc)}`);
    yield put({ type: BC_LEVEL_REPORT.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: BC_LEVEL_REPORT.FAILURE });
  }
}

export { downloadAgReportAsync, bcLevelReportDataAsync };
