import { combineReducers } from 'redux';

import user from './UserReducer';
import businessCenter from './BusinessCenterReducer';
import itemGroup from './ItemGroupReducer';
import attributeGroup from './AttributeGroupReducer';
import osdAttribute from './OsdAttributeReducer';
import item from './ItemReducer';
import userInput from './UserInputReducer';
import createRevisedOsdAttribute from './CreateOsdAttributeReducer';
import getLotStatus from './GetLotStatusReducer';
import updateLotStatus from './UpdateLotStatusReducer';
import recalculate from './RecalculateReducer';
import osdFilter from './OsdFiltersReducer';
import paperLotNumberFilter from './PaperLotNumberReducer';
import revisedPaperLotNumberFilter from './RevisedPaperLotNumberReducer';
import reports from './ReportDownloaderReducer';
import packFilter from './PackFilterReducer';
import sizeFilter from './SizeFilterReducer';
import revisedOsdAttribute from './RevisedOsdAttributeReducer';
import catManEventFilter from './CatManEventFilterReducer';
import catManLotFilter from './CatManLotFilterReducer';

export default combineReducers({
  user,
  businessCenter,
  itemGroup,
  attributeGroup,
  osdAttribute,
  item,
  userInput,
  createRevisedOsdAttribute,
  getLotStatus,
  updateLotStatus,
  recalculate,
  osdFilter,
  paperLotNumberFilter,
  revisedPaperLotNumberFilter,
  reports,
  packFilter,
  sizeFilter,
  revisedOsdAttribute,
  catManEventFilter,
  catManLotFilter
});
