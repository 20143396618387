import React, { useState, useEffect } from 'react';
import { Input, Divider, Button } from 'antd';
import SearchIcon from './icons/Search';
import CloseIcon from './icons/Close';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { prepareTableColumnValues } from 'managers/components/ColumnsEditorLogic';
import { range } from 'lodash/util';
export default function ColumnsEditor({ osdAttributesList, isDefaultAllSelected, handleOnselectChange, hierarchyId }) {
  const [options, setOptions] = useState([]);
  const [selectedAttribute, setSelectedAttributes] = useState([]);
  const [performAll, setPerformAll] = useState('Add All');
  const [searchText, setSearchText] = useState('');
  const [isUserSearch, setIsUserSearch] = useState(false);

  useEffect(() => {
    const selectedAttribute = osdAttributesList.filter(
      attribute => attribute.describingOrDetermining === 'Determining'
    );
    const selectedOption = osdAttributesList.filter(attribute => attribute.describingOrDetermining === 'Describing');
    setOptions(selectedOption);
    setSelectedAttributes(selectedAttribute);
    setIsUserSearch(false);
    setSearchText('');
  }, [osdAttributesList]);

  useEffect(() => {
    if (isDefaultAllSelected) {
      setSelectedAttributes(osdAttributesList);
    }
  }, [isDefaultAllSelected]);

  useEffect(() => {
    const selectedColumnKeys = selectedAttribute.map(item => prepareTableColumnValues(item));
    handleOnselectChange(selectedColumnKeys);
  }, [selectedAttribute]);

  useEffect(() => {
    if (options && options.length === 0) {
      setPerformAll('Remove All');
    }
    if (selectedAttribute && selectedAttribute.length === 0) {
      setPerformAll('Add All');
    }
  }, [selectedAttribute, options]);

  useEffect(() => {
    if (isUserSearch) {
      const remaining = osdAttributesList.filter(item => {
        const index = selectedAttribute.findIndex(_item => _item.keyName === item.keyName);
        if (index === -1) {
          return item;
        }
      });

      if (remaining && remaining.length > 0) {
        const filtered = remaining.filter(item => item.keyName.toLowerCase().includes(searchText.toLowerCase()));
        if (searchText !== '') {
          setOptions([...filtered]);
        } else {
          setOptions([...remaining]);
        }
      }
    }
  }, [searchText]);

  const handleAttributeAdd = attribute => {
    const index = selectedAttribute.indexOf(attribute);
    if (index > -1) {
      const modified = [...selectedAttribute];
      modified[index] = attribute;
      setSelectedAttributes(modified);
    } else {
      const modified = [...selectedAttribute, attribute];
      modified.sort((a, b) => (parseInt(a.catmanPriority) > parseInt(b.catmanPriority) ? 1 : -1));
      setSelectedAttributes(modified);
    }
    const indexOfOsd = options.indexOf(attribute);
    options.splice(indexOfOsd, 1);
  };
  const handleAttributeRemove = attribute => {
    const modified = [...selectedAttribute];
    const index = modified.indexOf(attribute);
    if (index !== -1) {
      modified.splice(index, 1);
      setSelectedAttributes(modified);
    }
    const modifiedOptions = [...options, attribute];
    modifiedOptions.sort((a, b) => (parseInt(a.catmanPriority) > parseInt(b.catmanPriority) ? 1 : -1));
    setOptions(modifiedOptions);
  };

  const handleSearch = searchKey => {
    setIsUserSearch(true);
    setSearchText(searchKey);
  };

  const handleAddOrRemoveAll = () => {
    if (performAll === 'Add All') {
      setPerformAll('Remove All');
      setSelectedAttributes([...osdAttributesList]);
      setOptions([]);
    } else {
      setPerformAll('Add All');
      setOptions([...osdAttributesList]);
      setSelectedAttributes([]);
    }
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const reOrdered = reorder([...selectedAttribute], result.source.index, result.destination.index);

    setSelectedAttributes(reOrdered);
  };
  return (
    <div className="column-editor-base">
      <div className="column-editor-top-search-wrapper">
        <Input
          className="osd-popup-search"
          size="large"
          placeholder="Search attributes"
          prefix={<SearchIcon size={20} color="rgba(0,0,0,0.4)" />}
          onChange={e => handleSearch(e.target.value)}
          value={searchText}
        />
        <div className="add-all-btn">
          {performAll === 'Remove All' ? (
            <Button type="primary" onClick={handleAddOrRemoveAll} danger>
              {performAll}
            </Button>
          ) : (
            <Button type="primary" onClick={handleAddOrRemoveAll}>
              {performAll}
            </Button>
          )}
        </div>
      </div>
      <div className="column-editor-top">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="osd-droppable">
            {(provided, snapshot) => (
              <ul {...provided.droppableProps} ref={provided.innerRef}>
                {selectedAttribute ? (
                  selectedAttribute.map((item, i) => {
                    return (
                      <Draggable draggableId={item.keyName} key={item.keyName} index={i}>
                        {(provided, snapshot) => (
                          <li
                            key={item.keyName}
                            draggable="true"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="column-editor-top-element">
                              <div className="column-editor-icon">
                                <svg width="15" height="8" viewBox="0 0 15 8">
                                  <g id="column-editor-move-handle" data-name="Group 130" transform="translate(0 0.26)">
                                    <rect
                                      id="Rectangle_46"
                                      data-name="Rectangle 46"
                                      width="15"
                                      height="3"
                                      rx="1"
                                      transform="translate(0 4.74)"
                                      fill="#CBCBCB"
                                    />
                                    <rect
                                      id="Rectangle_48"
                                      data-name="Rectangle 48"
                                      width="15"
                                      height="3"
                                      rx="1"
                                      transform="translate(0 -0.26)"
                                      fill="#CBCBCB"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <div
                                className={
                                  'column-editor-text' +
                                  (item.describingOrDetermining == 'Determining' ? '-det' : '-des')
                                }
                              >
                                {item.keyName}
                              </div>
                              <div
                                className="column-editor-remove"
                                onClick={() => {
                                  handleAttributeRemove(item);
                                }}
                              >
                                <CloseIcon color="rgba(255,0,0,0.6)" size={20} />
                              </div>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    );
                  })
                ) : (
                  <></>
                )}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <Divider dashed />

      <div className="column-editor-bottom">
        <ul>
          {options ? (
            options.map((item, i) => {
              return (
                <li key={i}>
                  <div
                    className={
                      'column-editor-bottom-element' + (item.describingOrDetermining == 'Determining' ? '-det' : '-des')
                    }
                  >
                    <div
                      className={
                        'column-editor-bottom-text' + (item.describingOrDetermining == 'Determining' ? '-det' : '-des')
                      }
                    >
                      {item.keyName}
                    </div>
                    <div className="column-editor-bottom-button">
                      <button onClick={() => handleAttributeAdd(item)}>ADD</button>
                    </div>
                  </div>
                </li>
              );
            })
          ) : (
            <></>
          )}
        </ul>
      </div>
    </div>
  );
}
