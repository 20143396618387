import React from 'react';

function SearchIcon(props) {
  const color = props.color || '#000205';
  const scale = props.size || '24';
  const strokeWidth = props.stroke || '4';

  return (
    <svg height={scale} width={scale} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <g
        fill={color}
        stroke={color}
        strokeLinecap="square"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeWidth={strokeWidth}
      >
        <line fill="none" x1="44" x2="31.314" y1="44" y2="31.314" />
        <circle cx="20" cy="20" fill="none" r="16" stroke={color} />
      </g>
    </svg>
  );
}

export default SearchIcon;
