import { takeLatest } from 'redux-saga/effects';
import { ITEM, GET_LOT_STATUS, UPDATE_LOT_STATUS, RECALCULATE } from 'actions/actionTypes';
import { loadItemsAsync, loadLottStatusAsync, updateLottStatusAsync, recalculatePriceLotsAsync } from './ItemSaga';

const itemSaga = [
  takeLatest(ITEM.REQUEST, loadItemsAsync),
  takeLatest(GET_LOT_STATUS.REQUEST, loadLottStatusAsync),
  takeLatest(UPDATE_LOT_STATUS.REQUEST, updateLottStatusAsync),
  takeLatest(RECALCULATE.REQUEST, recalculatePriceLotsAsync)
];

export default itemSaga;
