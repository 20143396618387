import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from 'reducers';
import rootSaga from 'sagas';

import initialState from './initialState';

let middlewares = [];
const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);
if (process.env.NODE_ENV === `development`) {
  const logger = createLogger({ collapsed: true, duration: true, diff: true });
  middlewares.push(logger);
}

const store = createStore(rootReducer, initialState, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSaga);

export default store;
