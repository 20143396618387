import React from 'react';

function ExportIcon(props) {
  const fill = props.fill || 'currentColor';
  const secondaryfill = props.secondaryfill || fill;
  const width = props.width || '100%';
  const height = props.height || '100%';

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 19.81 23.195"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Group 99"
    >
      <g>
        <defs>
          <clipPath id="clip-path">
            <path id="Rectangle_35" d="M0 0h19.81v23.195H0z" fill="#1b8cd1" data-name="Rectangle 35" />
          </clipPath>
        </defs>
        <g id="Group_98" clipPath="url(#clip-path)" data-name="Group 98">
          <path
            id="Path_24"
            d="M25.781 3.495l-.7.706a.358.358 0 0 1-.507.007l-.743-.73v10.607a.839.839 0 0 1-.839.839h-.812a.839.839 0 0 1-.839-.839V3.481l-.74.726a.361.361 0 0 1-.507 0l-.7-.709a.358.358 0 0 1 .007-.507l1.967-1.936.969-.955a.36.36 0 0 1 .5 0l.966.949 1.97 1.936a.361.361 0 0 1 0 .507"
            fill="#1b8cd1"
            transform="translate(-12.683)"
            data-name="Path 24"
          />
          <path
            id="Path_25"
            d="M15.421 17.19H13.59a.389.389 0 0 0-.389.389V19.2a.389.389 0 0 0 .389.389h1.831a1.99 1.99 0 0 1 1.991 1.991v8.528a1.99 1.99 0 0 1-1.991 1.992H4.389A1.992 1.992 0 0 1 2.4 30.106v-8.527a2 2 0 0 1 1.991-1.991H6.22a.389.389 0 0 0 .389-.389v-1.62a.389.389 0 0 0-.389-.389H4.389A4.4 4.4 0 0 0 0 21.579v8.528A4.393 4.393 0 0 0 4.389 34.5h11.032a4.389 4.389 0 0 0 4.389-4.389v-8.532a4.393 4.393 0 0 0-4.389-4.389"
            fill="#1b8cd1"
            transform="translate(0 -11.301)"
            data-name="Path 25"
          />
        </g>
      </g>
    </svg>
  );
}

export default ExportIcon;
