import _ from 'lodash';

const selectBusinessCenterList = state => _.get(state.businessCenter, 'data', []);
const selectItemGroupList = state => _.get(state.itemGroup, 'data', []);
const selectAttributeGroupList = state => _.get(state.attributeGroup, 'data', []);
const selectOsdAttributeList = state => _.get(state.osdAttribute, 'data', []);
const selectRevisedOsdAttributeList = state => _.get(state.revisedOsdAttribute, 'data', []);
const selectUpsertOsd = state => _.get(state.createRevisedOsdAttribute, 'data', null);
const selectOsdFilters = state => _.get(state.osdFilter, 'data', null);
const selectedPaperLotNumberFilters = state => _.get(state.paperLotNumberFilter, 'data', []);
const selectedRevisedPaperLotNumberFilters = state => _.get(state.revisedPaperLotNumberFilter, 'data', []);
const selectedPackFilters = state => _.get(state.packFilter, 'data', []);
const selectedSizeFilters = state => _.get(state.sizeFilter, 'data', []);
const isLoadingAttributeGroup = state => _.get(state.attributeGroup, 'fetching', false);
const isLoadingItemGroup = state => _.get(state.itemGroup, 'fetching', false);
const selectedCatManEventFilters = state => _.get(state.catManEventFilter, 'data', []);
const selectedCatManLotFilters = state => _.get(state.catManLotFilter, 'data', []);

export {
  selectBusinessCenterList,
  selectItemGroupList,
  selectAttributeGroupList,
  selectOsdAttributeList,
  selectRevisedOsdAttributeList,
  selectUpsertOsd,
  selectOsdFilters,
  selectedPaperLotNumberFilters,
  selectedRevisedPaperLotNumberFilters,
  selectedPackFilters,
  selectedSizeFilters,
  isLoadingAttributeGroup,
  isLoadingItemGroup,
  selectedCatManEventFilters,
  selectedCatManLotFilters
};
