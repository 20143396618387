import { action } from 'reduxHelpers';
import {
  USER,
  BUSINESS_CENTER,
  ITEM_GROUP,
  ATTRIBUTE_GROUP,
  OSD_ATTRIBUTE,
  REVISED_OSD_ATTRIBUTE,
  ITEM,
  GET_LOT_STATUS,
  UPDATE_LOT_STATUS,
  UPSERT_REVISED_OSD,
  USER_INPUTS,
  RECALCULATE,
  OSD_FILTERS,
  PAPER_LOT_NUMBER_FILTER,
  REVISED_PAPER_LOT_NUMBER_FILTER,
  AG_LEVEL_REPORT,
  PACK_FILTER,
  SIZE_FILTER,
  CAT_MAN_EVENT_FILTER,
  CAT_MAN_LOT_FILTER,
  BC_LEVEL_REPORT
} from './actionTypes';

const loadUserDetails = userId => action(USER.REQUEST, { userId });
const loadBusinessCenters = () => action(BUSINESS_CENTER.REQUEST, {});
const loadItemGroup = payload => action(ITEM_GROUP.REQUEST, payload);
const loadAttributeGroup = payload => action(ATTRIBUTE_GROUP.REQUEST, payload);
const loadOsdAttribute = payload => action(OSD_ATTRIBUTE.REQUEST, payload);
const loadOsdFilters = payload => action(OSD_FILTERS.REQUEST, payload);
const loadItems = payload => action(ITEM.REQUEST, payload);
const saveUserInputs = payload => action(USER_INPUTS.REQUEST, payload);
const loadRevisedOsdAttribute = payload => action(REVISED_OSD_ATTRIBUTE.REQUEST, payload);
const createOrUpdateRevisedOsdAttribute = payload => action(UPSERT_REVISED_OSD.REQUEST, payload);
const loadLotStatus = payload => action(GET_LOT_STATUS.REQUEST, payload);
const updateLotStatus = payload => action(UPDATE_LOT_STATUS.REQUEST, payload);
const recalculatePaperLot = payload => action(RECALCULATE.REQUEST, payload);
const loadPaperLotNumberFilter = payload => action(PAPER_LOT_NUMBER_FILTER.REQUEST, payload);
const loadRevisedPaperLotNumberFilter = payload => action(REVISED_PAPER_LOT_NUMBER_FILTER.REQUEST, payload);
const downloadAgLevelReport = payload => action(AG_LEVEL_REPORT.REQUEST, payload);
const loadPackFilter = payload => action(PACK_FILTER.REQUEST, payload);
const loadSizeFilter = payload => action(SIZE_FILTER.REQUEST, payload);
const loadCatManEventFilter = payload => action(CAT_MAN_EVENT_FILTER.REQUEST, payload);
const loadCatManLotFilter = payload => action(CAT_MAN_LOT_FILTER.REQUEST, payload);
const resetItems = () => action('PAPERLOT_ITEM_RESET', {});
const getBcLevelReportData = bc => action(BC_LEVEL_REPORT.REQUEST, { bc });

export {
  loadUserDetails,
  loadBusinessCenters,
  loadItemGroup,
  loadAttributeGroup,
  loadOsdAttribute,
  loadItems,
  saveUserInputs,
  loadRevisedOsdAttribute,
  createOrUpdateRevisedOsdAttribute,
  loadLotStatus,
  updateLotStatus,
  recalculatePaperLot,
  loadOsdFilters,
  loadPaperLotNumberFilter,
  loadRevisedPaperLotNumberFilter,
  downloadAgLevelReport,
  loadPackFilter,
  loadSizeFilter,
  loadCatManEventFilter,
  loadCatManLotFilter,
  resetItems,
  getBcLevelReportData
};
