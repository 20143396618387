import _ from 'lodash';

const isLoadingBcReport = state => _.get(state.reports, 'isLoadingBcReport', false);
const bcReportData = state => _.get(state.reports, 'bcReportData', {});
const bcReportErrorSelector = state => _.get(state.reports, 'bcReportError', null);

const isLoadingAgReport = state => _.get(state.reports, 'isLoadingAgReport', null);
const agReportData = state => _.get(state.reports, 'agReportData', {});

export { bcReportData, isLoadingBcReport, bcReportErrorSelector, isLoadingAgReport, agReportData };
