import { createRequestTypes } from 'reduxHelpers';

const USER = createRequestTypes('USER');
const BUSINESS_CENTER = createRequestTypes('BUSINESS_CENTER');
const ITEM_GROUP = createRequestTypes('ITEM_GROUP');
const ATTRIBUTE_GROUP = createRequestTypes('ATTRIBUTE_GROUP');
const OSD_ATTRIBUTE = createRequestTypes('OSD_ATTRIBUTE');
const ITEM = createRequestTypes('ITEM');
const USER_INPUTS = createRequestTypes('USER_INPUTS');
const UPSERT_REVISED_OSD = createRequestTypes('UPSERT_REVISED_OSD');
const GET_LOT_STATUS = createRequestTypes('GET_LOT_STATUS');
const UPDATE_LOT_STATUS = createRequestTypes('UPDATE_LOT_STATUS');
const RECALCULATE = createRequestTypes('RECALCULATE');
const OSD_FILTERS = createRequestTypes('OSD_FILTERS');
const PAPER_LOT_NUMBER_FILTER = createRequestTypes('PAPER_LOT_NUMBER_FILTER');
const REVISED_PAPER_LOT_NUMBER_FILTER = createRequestTypes('REVISED_PAPER_LOT_NUMBER_FILTER');
const AG_LEVEL_REPORT = createRequestTypes('AG_LEVEL_REPORT');
const PACK_FILTER = createRequestTypes('PACK_FILTER');
const SIZE_FILTER = createRequestTypes('SIZE_FILTER');
const CAT_MAN_EVENT_FILTER = createRequestTypes('CAT_MAN_EVENT_FILTER');
const CAT_MAN_LOT_FILTER = createRequestTypes('CAT_MAN_LOT_FILTER');
const REVISED_OSD_ATTRIBUTE = createRequestTypes('REVISED_OSD_ATTRIBUTE');
const BC_LEVEL_REPORT = createRequestTypes('BC_LEVEL_REPORT');

export {
  USER,
  BUSINESS_CENTER,
  ITEM_GROUP,
  ATTRIBUTE_GROUP,
  OSD_ATTRIBUTE,
  ITEM,
  UPSERT_REVISED_OSD,
  USER_INPUTS,
  GET_LOT_STATUS,
  UPDATE_LOT_STATUS,
  RECALCULATE,
  OSD_FILTERS,
  PAPER_LOT_NUMBER_FILTER,
  REVISED_PAPER_LOT_NUMBER_FILTER,
  AG_LEVEL_REPORT,
  PACK_FILTER,
  SIZE_FILTER,
  REVISED_OSD_ATTRIBUTE,
  CAT_MAN_EVENT_FILTER,
  CAT_MAN_LOT_FILTER,
  BC_LEVEL_REPORT
};
