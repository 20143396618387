import _ from 'lodash';
import moment from 'moment';

const formatDate = date => {
  return (
    [padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate()), date.getFullYear()].join('/') +
    ' ' +
    [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(':')
  );
};

const formatToUtcDate = date => {
  return (
    [padTo2Digits(date.getUTCMonth() + 1), padTo2Digits(date.getUTCDate()), date.getFullYear()].join('/') +
    ' ' +
    [padTo2Digits(date.getUTCHours()), padTo2Digits(date.getUTCMinutes()), padTo2Digits(date.getUTCSeconds())].join(':')
  );
};
const padTo2Digits = num => {
  return num.toString().padStart(2, '0');
};

const getUserPermission = user => {
  if (user?.permissions) {
    if (user.permissions.includes('PAPERLOTAPP.EDIT.ALL')) {
      return 'EDIT';
    } else if (user.permissions.includes('PAPERLOTAPP.VIEW.ALL')) {
      return 'VIEW';
    } else {
      return null;
    }
  }
};

const formatSelectedKey = selectedKeys => {
  if (selectedKeys.length === 0) {
    return undefined;
  }
  return selectedKeys[0]?.split('-', 1)[0];
};

const getTheLogicValue = (filtersObject, dataIndex, selectedNumberFilterOption, setValue) => {
  setTimeout(() => {
    if (Object.keys(filtersObject).length === 0) {
      return;
    }
    if (
      filtersObject[dataIndex] !== null &&
      filtersObject[dataIndex][0].split('-', 2)[1] !== selectedNumberFilterOption
    ) {
      setValue(filtersObject[dataIndex][0].split('-', 2)[1]);
    }
  }, 150);
};

const removeTrailingPipe = str => {
  return !_.isEmpty(str) && _.endsWith(str, ' | ') ? _.slice(str, 0, -3).join('') : str;
};

const dateFormatter = (date, format = 'MM/DD/YYYY') => {
  return moment(date || null).format(format);
};

export {
  formatDate,
  formatToUtcDate,
  getUserPermission,
  formatSelectedKey,
  getTheLogicValue,
  removeTrailingPipe,
  dateFormatter
};
