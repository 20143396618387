import initialState from 'store/initialState';
import { BC_LEVEL_REPORT, AG_LEVEL_REPORT } from 'actions/actionTypes';

const reports = (state = initialState.reports, action) => {
  switch (action.type) {
    case AG_LEVEL_REPORT.REQUEST:
      return { ...state, isLoadingAgReport: true, agReportData: {} };
    case AG_LEVEL_REPORT.SUCCESS:
      return { ...state, isLoadingAgReport: false, agReportData: { ...action.payload } };
    case AG_LEVEL_REPORT.FAILURE:
      return { ...state, isLoadingAgReport: false };
    case BC_LEVEL_REPORT.REQUEST:
      return { ...state, isLoadingBcReport: true, bcReportData: {}, bcReportError: null };
    case BC_LEVEL_REPORT.SUCCESS:
      return { ...state, isLoadingBcReport: false, bcReportData: { ...action.payload } };
    case BC_LEVEL_REPORT.FAILURE:
      return { ...state, isLoadingBcReport: false, bcReportError: 'No reports found' };
    default:
      return state;
  }
};
export default reports;
