import { takeLatest } from 'redux-saga/effects';
import {
  BUSINESS_CENTER,
  ITEM_GROUP,
  ATTRIBUTE_GROUP,
  OSD_ATTRIBUTE,
  REVISED_OSD_ATTRIBUTE,
  UPSERT_REVISED_OSD,
  OSD_FILTERS,
  PAPER_LOT_NUMBER_FILTER,
  REVISED_PAPER_LOT_NUMBER_FILTER,
  PACK_FILTER,
  SIZE_FILTER,
  CAT_MAN_EVENT_FILTER,
  CAT_MAN_LOT_FILTER
} from 'actions/actionTypes';
import {
  loadBusinessCentersAsync,
  loadItemGroupsAsync,
  loadAttributeGroupAsync,
  loadOSDAttributesAsync,
  loadRevisedOsdAttributesAsync,
  createOrUpdateRevisedOsdAsync,
  loadOsdFiltersAsync,
  loadPaperLotNumberFiltersAsync,
  loadRevisedPaperLotNumberFiltersAsync,
  loadPackFiltersAsync,
  loadSizeFiltersAsync,
  loadCatManLotFiltersAsync,
  loadCatManEventFiltersAsync
} from './HierarchySaga';

const hierarchySaga = [
  takeLatest(BUSINESS_CENTER.REQUEST, loadBusinessCentersAsync),
  takeLatest(ITEM_GROUP.REQUEST, loadItemGroupsAsync),
  takeLatest(ATTRIBUTE_GROUP.REQUEST, loadAttributeGroupAsync),
  takeLatest(OSD_ATTRIBUTE.REQUEST, loadOSDAttributesAsync),
  takeLatest(REVISED_OSD_ATTRIBUTE.REQUEST, loadRevisedOsdAttributesAsync),
  takeLatest(UPSERT_REVISED_OSD.REQUEST, createOrUpdateRevisedOsdAsync),
  takeLatest(OSD_FILTERS.REQUEST, loadOsdFiltersAsync),
  takeLatest(PAPER_LOT_NUMBER_FILTER.REQUEST, loadPaperLotNumberFiltersAsync),
  takeLatest(REVISED_PAPER_LOT_NUMBER_FILTER.REQUEST, loadRevisedPaperLotNumberFiltersAsync),
  takeLatest(PACK_FILTER.REQUEST, loadPackFiltersAsync),
  takeLatest(SIZE_FILTER.REQUEST, loadSizeFiltersAsync),
  takeLatest(CAT_MAN_EVENT_FILTER.REQUEST, loadCatManEventFiltersAsync),
  takeLatest(CAT_MAN_LOT_FILTER.REQUEST, loadCatManLotFiltersAsync)
];

export default hierarchySaga;
