import { put } from 'redux-saga/effects';
import { getRequest, postRequest } from '_http';
import { ITEM, GET_LOT_STATUS, UPDATE_LOT_STATUS, RECALCULATE } from 'actions/actionTypes';

import { action } from 'reduxHelpers';

function* loadItemsAsync(payload) {
  try {
    const { bc, ig, ag, currentPage, sortParams, pageSize, filters } = payload;
    const _sortParams =
      sortParams && Object.keys(sortParams).length > 0
        ? encodeURIComponent(JSON.stringify(sortParams))
        : encodeURIComponent(JSON.stringify({ desc: ['kviItem'] }));
    const _filter = filters ? encodeURIComponent(JSON.stringify(filters)) : encodeURIComponent(JSON.stringify({}));
    const url = `/items?bc=${encodeURIComponent(bc)}&ig=${encodeURIComponent(ig)}&ag=${encodeURIComponent(
      ag
    )}&sort-params=${_sortParams}&filters=${_filter}&current-page=${currentPage}&page-size=${pageSize}`;
    const response = yield getRequest(url);
    let data = [];
    if (response && response.data) {
      response.data.data.forEach(a => {
        let fo = flattenObj(a);
        data.push(fo);
      });
      yield put({ type: ITEM.SUCCESS, payload: { ...response.data, data } });
    }
  } catch (error) {
    yield put({ type: ITEM.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'ITEM LOADING ERROR'
      })
    );
  }
}

function* loadLottStatusAsync(payload) {
  const { hierarchyId } = payload;
  try {
    const url = `/paper-lot/status?hierarchy-id=${hierarchyId}`;
    const response = yield getRequest(url);
    if (response && response.data) {
      yield put({ type: GET_LOT_STATUS.SUCCESS, payload: response.data });
    }
  } catch (error) {
    yield put({ type: GET_LOT_STATUS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'GET LOT STATUS LOADING ERROR'
      })
    );
  }
}

function* updateLottStatusAsync(payload) {
  const { hierarchyId, updateStatusDto } = payload;
  try {
    const url = `/paper-lot/status?hierarchy-id=${hierarchyId}`;
    const response = yield postRequest(url, updateStatusDto);
    if (response) {
      yield put({ type: UPDATE_LOT_STATUS.SUCCESS, payload: response.data });
      yield put({ type: GET_LOT_STATUS.REQUEST, hierarchyId });
    }
  } catch (error) {
    if (error?.response?.data?.code === '400') {
      yield put({ type: UPDATE_LOT_STATUS.FAILURE, payload: error?.response?.data?.message?.message });
      yield put({ type: GET_LOT_STATUS.REQUEST, hierarchyId });
    }
    yield put({ type: UPDATE_LOT_STATUS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'UPDATE LOT STATUS LOADING ERROR'
      })
    );
  }
}

const flattenObj = ob => {
  let result = {};
  for (const i in ob) {
    if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
      const temp = flattenObj(ob[i]);
      for (const j in temp) {
        result[j] = temp[j];
      }
    } else {
      result[i] = ob[i];
    }
  }
  return result;
};

function* recalculatePriceLotsAsync(payload) {
  const { hierarchyId } = payload;
  try {
    const url = `/items/regenerate-paper-lot?hierarchy-id=${hierarchyId}`;
    const response = yield getRequest(url);
    if (response?.data?.recalculationStatus === 'Skipped') {
      yield put(
        action('SHOW_NOTIFICATION', {
          className: 'warning',
          description: 'No user input change found',
          message: 'RECALCULATE SKIPPED'
        })
      );
    } else {
      yield put(
        action('SHOW_NOTIFICATION', {
          className: 'success',
          message: 'RECALCULATE SUCCESS'
        })
      );
    }
    yield put({ type: RECALCULATE.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: RECALCULATE.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'RECALCULATE ERROR'
      })
    );
  }
}

export { loadItemsAsync, loadLottStatusAsync, updateLottStatusAsync, recalculatePriceLotsAsync };
