import React from 'react';

function NextIcon({ size, flip }) {
  const scale = size || '24';

  return (
    <svg
      height={scale}
      width={scale}
      style={{ transform: flip ? 'scaleX(-1)' : '' }}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M10.02 6L8.61 7.41 13.19 12l-4.58 4.59L10.02 18l6-6-6-6z" fill="#1b8cd1" />
      </g>
    </svg>
  );
}

export default NextIcon;
